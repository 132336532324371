import React from "react"
import styles from './appbanner.scss'
import logo from '../../assets/images/mandhaar.png';

const AppBanner = ():JSX.Element => {
    return (
        <div className={styles.appindrobanner}>
            <img className={styles.logo} src={logo} alt="" />
        </div>
    )
}

export default AppBanner
