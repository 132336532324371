import React from 'react';

import {
    ApiOutlined,
    AuditOutlined,
    BarChartOutlined,
    CoffeeOutlined,
    UsergroupAddOutlined,
} from '@ant-design/icons';
import { AiOutlineRocket } from 'react-icons/ai';

export default [
    {
        name: 'Dashboard',
        path: '/dashboard',
        icon: <BarChartOutlined />,
        role: ['ADMIN'],
    },
    {
        name: 'Service',
        path: '/services/kitchen-locations',
        role: ['ADMIN'],
        icon: <ApiOutlined />,
        children: [
            {
                name: 'Kitchen Locations',
                path: '/services/kitchen-locations',
                role: ['ADMIN'],
            },
            {
                name: 'Orders',
                path: '/services/orders',
                role: ['ADMIN'],
            },
        ],
    },
    {
        name: 'Companies',
        path: '/companies/branches',
        icon: <AuditOutlined />,
        role: ['ADMIN'],
        children: [
            {
                name: 'Branches',
                path: '/companies/branches',
                role: ['ADMIN'],
            },
            {
                name: 'Employees',
                path: '/companies/employees',
                role: ['ADMIN'],
            },
        ],
    },
    {
        name: 'Kitchens',
        path: '/kitchen/ingredients',
        icon: <CoffeeOutlined />,
        role: ['ADMIN'],
        children: [
            {
                name: 'Ingredients',
                path: '/kitchen/ingredients',
                role: ['ADMIN'],
            },
            {
                name: 'Preparations',
                path: '/kitchen/preparations',
                role: ['ADMIN'],
            },
            {
                name: 'Utensils',
                path: '/kitchen/utensils',
                role: ['ADMIN'],
            },
            {
                name: 'Dishes',
                path: '/kitchen/dishes',
                role: ['ADMIN'],
            },
            {
                name: 'Menus',
                path: '/kitchen/menus',
                role: ['ADMIN'],
            },
        ],
    },
    // {
    // 	name: 'Suppliers',
    // 	path: '/suppliers',
    // 	icon: <AiOutlineRocket />,
    // 	role: ['ADMIN']
    // },
    {
        name: 'Suppliers',
        path: '/suppliers',
        icon: <AiOutlineRocket />,
        role: ['ADMIN'],
    },
    {
        name: 'Users',
        path: '/users',
        icon: <UsergroupAddOutlined />,
        role: ['ADMIN'],
    },
];
