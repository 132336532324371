// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._gV2ps7w6wI8JlvPQwRN{margin-bottom:20px;display:flex;justify-content:space-between;text-transform:capitalize}", "",{"version":3,"sources":["webpack://./src/Layout/page-hoc.scss"],"names":[],"mappings":"AAEgC,sBAC/B,kBAAA,CACA,YAAA,CACA,6BAAA,CACA,yBAAA","sourcesContent":["@import \"./src/assets/scss/variables.scss\";\n                                @import \"./src/assets/scss/mixins.scss\";\n                                .app_header {\n\tmargin-bottom: 20px;\n\tdisplay: flex;\n\tjustify-content: space-between;\n\ttext-transform: capitalize;\n}\n\n                                "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app_header": "_gV2ps7w6wI8JlvPQwRN"
};
export default ___CSS_LOADER_EXPORT___;
