// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Rsd6tGoMzWZk_aV0oyjI{background-color:#494234;display:flex;height:100%;flex-direction:row;justify-content:space-around;align-items:center}.Rsd6tGoMzWZk_aV0oyjI .L8NLWvE8z3U2SZ7KpSWn{width:300px;height:auto}", "",{"version":3,"sources":["webpack://./src/components/AppBanner/appbanner.scss","webpack://./src/assets/scss/_variables.scss"],"names":[],"mappings":"AAEgC,sBAC/B,wBCKS,CDJT,YAAA,CACA,WAAA,CACA,kBAAA,CACA,4BAAA,CACA,kBAAA,CAEA,4CACC,WAAA,CACA,WAAA","sourcesContent":["@import \"./src/assets/scss/variables.scss\";\n                                @import \"./src/assets/scss/mixins.scss\";\n                                .appindrobanner {\n\tbackground-color: $primary;\n\tdisplay: flex;\n\theight: 100%;\n\tflex-direction: row;\n\tjustify-content: space-around;\n\talign-items: center;\n\n\t.logo {\n\t\twidth: 300px;\n\t\theight: auto;\n\t}\n}\n\n                                ","// $base-font-size: 1.4rem;\n// font-weight 300;400;500;600\n$app-bg: #f9f6f3;\n$text-color: #444444;\n$dark-blue: #3e4b5e;\n$gray: #9ea0a5;\n\n$emerald: #183a3a;\n$primary: #494234;\n$secondary: #c7a474;\n$secondary: #b38e69;\n\n$white: #ffffff;\n$black: #000000;\n$border-color: #f0f0f0;\n$border-color-base: #d9d9d9;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appindrobanner": "Rsd6tGoMzWZk_aV0oyjI",
	"logo": "L8NLWvE8z3U2SZ7KpSWn"
};
export default ___CSS_LOADER_EXPORT___;
