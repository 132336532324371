// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../node_modules/normalize.css/normalize.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "h1{font-family:\"Open Sans\",sans-serif;font-weight:400}h1{font-size:2.8rem;font-weight:400}:root{--bg-color: #f9f6f3;font-size:62.75%}[id=app]{height:100%;background-color:var(--bg-color)}", "",{"version":3,"sources":["webpack://./src/assets/scss/_mixins.scss","webpack://./src/assets/scss/main.scss"],"names":[],"mappings":"AAAA,GACC,kCAAA,CACA,eAAA,CAED,GAGC,gBAAA,CACA,eAAA,CCHD,MACC,mBAAA,CAEA,gBAAA,CAGD,SACC,WAAA,CACA,gCAAA","sourcesContent":["%title {\n\tfont-family: 'Open Sans', sans-serif;\n\tfont-weight: 400;\n}\n%title1 {\n\t@extend %title;\n\n\tfont-size: 2.8rem;\n\tfont-weight: 400;\n}\n","@import \"./src/assets/scss/variables.scss\";\n                                @import \"./src/assets/scss/mixins.scss\";\n                                @import './fontface';\n@import 'normalize.css';\n\n:root {\n\t--bg-color: #{$app-bg};\n\n\tfont-size: 62.75%;\n}\n\n[id=\"app\"] {\n\theight: 100%;\n\tbackground-color: var(--bg-color);\n}\n// html {\n//   font-size: 62.75%;\n// }\n\n// body {\n//   font-size: $base-font-size;\n//   color: $text-color;\n//   background-color: var(--bg-color);\n// }\n\nh1 {\n\t@extend %title1;\n}\n\n                                "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
