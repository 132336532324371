import { Layout, Menu } from 'antd';
import React, { ReactNode } from 'react';
import { Link, useHistory, Redirect } from 'react-router-dom';
import menuConfig from './menuConfig';
import styles from './page-hoc.scss';

console.log(styles);
const { Sider, Content } = Layout;
const { SubMenu } = Menu;
// fetch role from user data;
const tempRole = ['ADMIN'];

interface MenuItemType {
    name: string;
    path?: string;
    icon?: ReactNode;
    role: string[];
}
interface MenuType extends MenuItemType {
    children?: MenuItemType[];
}
interface PageLayoutTypes {
    children?: ReactNode;
    // any other props that come into the component
}
const PageLayoutHOC = ({ children }: PageLayoutTypes): JSX.Element => {
    const hasRole = (role: MenuItemType['role']) => true;
    // !!intersect(role, tempRole)?.length;

    const { location } = useHistory();
    if (location.pathname === '/') {
        return <Redirect to="/dashboard" />;
    }
    const renderMenu = (menuItems: MenuType[]) =>
        menuItems?.map(
            (item) =>
                hasRole(item.role) &&
                (item?.children ? (
                    <SubMenu key={item.name} icon={item.icon} title={item.name}>
                        {renderMenu(item?.children)}
                    </SubMenu>
                ) : (
                    <Menu.Item icon={item.icon} key={item.name}>
                        <Link to={item.path || '/'}>{item.name}</Link>
                    </Menu.Item>
                )),
        );

    return (
        <Layout style={{ background: '#f9f6f3' }}>
            <Sider collapsible theme="dark" className={styles.left_sider}>
                <Menu
                    mode="inline"
                    theme="dark"
                    defaultSelectedKeys={['1']}
                    defaultOpenKeys={['sub1']}
                    style={{ height: '100%', minHeight: '100vh' }}
                >
                    {renderMenu(menuConfig)}
                </Menu>
            </Sider>
            <Layout>
                <Content style={{ margin: '0 16px' }}>
                    <div
                        className="site-layout-background"
                        style={{ padding: 24 }}
                    >
                        {children}
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};
PageLayoutHOC.displayName = 'PageLayoutHOC';
PageLayoutHOC.defaultProps = {
    children: <>Not found</>,
};
export default PageLayoutHOC;
