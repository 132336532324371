import React from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageLayoutHOC from './Layout';
import AppBanner from './components/AppBanner';
import './assets/scss/main.scss'

const App = (): JSX.Element => {
    return (
        <>
            <Router>
                <Switch>
                    <Route exact path="/">
                            <AppBanner></AppBanner>
                    </Route>
                    <Route path="/home">
                        <PageLayoutHOC>home</PageLayoutHOC>
                    </Route>
                </Switch>
            </Router>
        </>
    );
};

export default App;
